import LocationOnIcon from '@material-ui/icons/LocationOn';
import React from "react";
interface IProps {
    latt: number,
    lngg: number,
    title: string,
    full_address: string
    is_open:boolean,
    markerValue: string,
    currentLocation: {
      latt: number,
      lngg: number
    },
  }

 const CustomMarker = ({ latt, lngg, title, markerValue, currentLocation, full_address ,is_open }: IProps) => {
    return (
        <div className="container-01" style={{ color: 'blue', height: "100px", width: "300px",padding:'20px'}}>
            <button id="button001" onClick={() => { window.open(`https://www.google.com/maps/dir/?api=1&origin=${currentLocation?.latt},${currentLocation?.lngg}&destination=${latt},${lngg}`) }}>
              <p style={{color:'black'}}>{full_address}</p>
              <p style={{color:'black'}}> {title}</p>
                 <p style={{color:'#00BFFF',fontWeight: 'bold',fontSize:'15'}}>{'status:'+is_open?'open':'closed'}</p>
            </button>
            <LocationOnIcon />
        </div>
    )
};
export default CustomMarker;