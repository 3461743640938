import React from "react";
import SearchIcon from '@material-ui/icons/Search';
import GoogleMapReact from 'google-map-react';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import CustomMarker from '../src/components/CustomMarker';
import CustomMarkerCurrent from "./components/CustomMarkerCurrent";
import StoreLocatorController, {
  Props,
  configJSON,
  storeInfo,
  StoreInformation
} from "./StoreLocatorController";
interface IProps {
  latt: number,
  lngg: number,
  title: string,
  full_address: string
  is_open:boolean,
  markerValue: string,
  currentLocation: {
    latt: number,
    lngg: number
  },
}
 
export default class StoreLocator extends StoreLocatorController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <div>
        <div style={{
          position: 'absolute',
          left: '30%',
          border: '1px solid black',
          height: '35px',
          display: 'flex',
          flexDirection: 'row',
          borderRadius: '30px',
          margin: '0 auto',
          backgroundColor: 'rgb(255, 255, 255)',
          zIndex: 1
        }}>
          <input name = 'userInput' style={{ width: '23rem', border: 'none', borderRadius: '70px' }} onChange={(element) => this.handleChange(element.target.value)} type="search" />
          <SearchIcon className="user-icon" style={{ width: '30px', height: '30px', marginTop: '3px', cursor: 'pointer' }} />
        </div>
        <GoogleMapReact key={this.state.latitutee} style={{ height: '100vh', width: '100%' }}
          bootstrapURLKeys={{ key: "AIzaSyAPGUoanvIaJoN2G2asrYqts3_xX3HCKXQ" }}
          defaultCenter={{
            lat: this.state.latitutee,
            lng: this.state.longitudee
          }}
          defaultZoom={11}
        >
          {
            this.state.store_locators.map((element: StoreInformation) => {
              return(
                <CustomMarker
                  latt={element?.attributes?.latitude}
                  lngg={element?.attributes?.longitude}
                  title={element?.attributes?.title}
                  full_address={element?.attributes?.full_address}
                  is_open={element?.attributes?.is_open}
                  markerValue={`${element?.attributes?.latitude, element?.attributes?.longitude}`}
                  currentLocation={{ latt: this.state.latitutee, lngg: this.state.longitudee }}
                />
              )
            })
          }
          <CustomMarkerCurrent
          />
        </GoogleMapReact>
      </div>
    );
  }
}